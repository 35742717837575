


















































import { Component, Prop } from 'vue-property-decorator';
import { Data } from '@/utils/types/WidgetData';
import VueBaseWidget from '@/utils/widgets/VueBaseWidget';
import Category from '@/models/graphql/Category';
import AvatarSoloWidget from '@/components/AvatarSoloWidget.vue';
import Exhibitor from '@/models/graphql/Exhibitor';
import FileResourceHelper from '@utils/helpers/FileResourceHelper';
import { mixins } from 'vue-class-component';
import VueRegisterStoreWidget from '@/utils/widgets/VueRegisterStoreWidget';
import { buildQueryDefinition } from '@/graphql/_Tools/GqlQueryDefinition';
import GqlEntityFilterType from '@/utils/enums/gql/GqlEntityFilterType';

@Component({
  components: { AvatarSoloWidget },
})
export default class CompanyLogoWidget extends mixins(VueBaseWidget, VueRegisterStoreWidget) {
  @Prop({ required: false, default: null })
  private entityCode!: string;

  @Prop({ required: false, default: null })
  private type!: string;

  @Prop({ required: false, default: null })
  private route!: string;

  @Prop({ required: false, default: () => [] })
  private data!: Data[];

  private FileResourceHelper = FileResourceHelper;

  private get exhibitor(): Exhibitor | null {
    if (this.data && this.data.length > 0) {
      return Exhibitor.hydrate(this.data[0]);
    }
    return null;
  }

  private get navigateTo(): string | null {
    if (this.route && this.exhibitor) {
      let r = this.route;
      const matches = this.route.match(/(%[a-zA-Z-_]+%)/gs);
      if (matches) {
        matches.forEach((m) => {
          const prop = m.replaceAll('%', '').trim();
          if (this.$props[prop]) {
            r = r.replaceAll(m, this.$props[prop]);
          }
          if ((this.exhibitor as unknown as Record<string, string>)[prop]) {
            r = r.replaceAll(m, (this.exhibitor as unknown as Record<string, string>)[prop]);
          }
          if (this.$route.params[prop]) {
            r = r.replaceAll(m, this.$route.params[prop]);
          }
        });
        return r;
      }
      if (r[r.length - 1] === '/') {
        return `${r}${this.exhibitor.uid}`;
      }
      return `${r}/${this.exhibitor.uid}`;
    }
    return null;
  }

  private get website(): string {
    if (this.exhibitor && this.exhibitor.urls && this.exhibitor.urls.length > 0) {
      const site = this.exhibitor.urls.find((url) => url.url && url.type && url.type === 'website');
      if (site && site.url) {
        if (!site.url.match(/^[a-zA-Z]+:\/\//)) {
          return `http://${site.url}`;
        }
        return site.url;
      }
    }
    return '';
  }

  private get categories(): string {
    const categories = '';
    if (this.exhibitor
      && this.exhibitor.categoriesInContext) {
      if (Array.isArray(this.exhibitor.categoriesInContext)
        && (this.exhibitor.categoriesInContext as Category[]).length > 0) {
        const entity = this.exhibitor.categoriesInContext as Category[];
        if (entity) {
          return `${entity.slice(0, 2).map((c) => c.name).join(' · ')}
          ${entity.length > 2 ? ` + ${entity.length - 2}` : ''}`;
        }
      }
    }
    return categories;
  }

  created(): void {
    if (this.payload
      && 'entityCode' in this.payload
      && this.payload.entityCode
      && 'type' in this.payload
      && this.payload.type) {
      const filter = JSON.parse(`{ "${this.payload.type}s": { "uid": "${this.payload.entityCode}" } }`);
      this.setDataConfig([{
        gqlDefinition: buildQueryDefinition({
          cacheable: true,
          filter: {
            type: GqlEntityFilterType.EXHIBITOR_FILTER,
            value: this.payload.type === 'exhibitor' ? { uid: this.payload.entityCoode } : filter,
          },
        }),
        operation: 'exhibitor',
        fragmentName: 'exhibitorLogoFragment',
        alias: this.storeName,
      }]);
    } else if (this.widget) {
      this.setDataConfig(
        undefined,
        true,
        `Missing entityCode or type in payload for ${this.widget.type} widget with id ${this.widget.id}`,
      );
    }
  }
}
